<template>
    <div>
        <div>
            <TableList ref="table-list" :title="''" :columns="columns" :rows="equipData" :keyField="'sysNodeName'"
                :toggleFields="['enabled']" :transCodes="transCodes" :controls="controls" :useFilter="false"
                :mode="'edit'" @button-click="handleListButtonClick" @toggle-changed="handleItemToggle"
                @row-click="handleListItemClick" />
            <SubEquipModal ref="SubEquipModal" :data="data" @save="addSubEquip" />
        </div>
    </div>
</template>

<script>
import TableList from '@src/views/component/v2.1/ListDetailView/TableList.vue';
import SubEquipModal from '../modal/SubEquipModal.vue';
// import backEndApi from '@src/api/backEndApi';

export default {
    components: {
        TableList,
        SubEquipModal,
    },
    props: ["data"],
    data() {
        return {
            rows: [],
            columns: [
                {
                    label: "노드 ID",
                    field: "sysNodeIdx",
                    type: "text",
                    // compositionFields: ['sysNodeName', 'sysNodeIdx'],
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: "노드명",
                    field: "sysNodeName",
                    type: "text",
                    // compositionFields: ['sysNodeName', 'sysNodeIdx'],
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: "계통 유형",
                    field: "sysType",
                    type: "text",
                },
                {
                    label: "설비 유형",
                    field: "equipType",
                    type: "text",
                },
                {
                    label: "구역",
                    field: "zoneIdx",
                    type: "text",
                },
                {
                    label: "사용여부",
                    field: "enabled",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: 'nodeType',
                    codes: this.$store.state.commonCodes.nodeType,
                },
                {
                    field: 'sysType',
                    codes: this.$store.state.transSysType,
                },
                {
                    field: 'equipType',
                    codes: this.$store.state.transEquipType,
                },
                {
                    field: 'zoneIdx',
                    codes: this.$store.state.zoneInfo,
                },
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            controls: [
                { event: 'delete', label: '-', class: 'btn-danger', },
                { event: 'status', label: '사용', class: 'bg-white text-dark', options: { enabled: "Y" }, },
                { event: 'status', label: '미사용', class: 'bg-white text-dark', options: { enabled: "N" }, },
                { event: 'regist', label: '+', class: 'btn-primary', },
            ],
            equipData: null,
            rowIdx: null,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    beforDestroyed() { },
    methods: {
        async setEquipData(node) {
            this.equipData = null;

            let checkEquipNode = node.subNodes.some(item => item.nodeType == 'Equip');

            if (checkEquipNode) {
                this.equipData = node.subNodes;
            } else {
                this.equipData = [];
            }
        },
        async handleListButtonClick(event, options) {
            console.log('event: ', event);
            const that = this;

            console.log('handleListButtonClick:', event, options);

            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteChecked();
                    break;

                // case 'refresh':
                //     await that.getDataList(event);
                //     break;

                // case 'status':
                //     await that.changeStatusChecked(options);
                //     break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },
        async handleItemToggle(key, field, status) {
            console.log('handleItemToggle :', key, field, status);
            this.equipData.find(item => item.sysNodeName == key)[field] = status;
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if (toggle || check) return;
            console.log('handleListItemClick. row:', row);
            console.log('handleListItemClick. index:', index);
            console.log("this.equipData : ", this.equipData)
            that.rowIdx = index;
            const find = this.equipData.find(item => item.sysNodeIdx == row.sysNodeIdx)
            await that.$refs.SubEquipModal.setData({ ...find });
            await this.$refs.SubEquipModal.showSubEquipModal();
        },

        async registNew() {
            this.rowIdx = null;
            await this.$refs.SubEquipModal.setData();
            await this.$refs.SubEquipModal.showSubEquipModal();
        },
        addSubEquip() {
            let equip = this.$refs.SubEquipModal.subEquipData;
            console.log(equip)

            if (equip) {
                // this.$set(this.data, this.data.length, addGroup)
                if (this.rowIdx !== null || undefined) {
                    this.$set(this.equipData, this.rowIdx, equip);
                } else {
                    let duplicate = this.equipData.find(item => item.sysNodeName == equip.sysNodeName);
                    if (duplicate) {
                        this.alertNoti("노드명이 중복됩니다.");
                        return;
                    } else {
                        this.$set(this.equipData, this.equipData.length, equip)
                    }
                }
            }
        },
        async deleteChecked() {

            try {
                const checked = this.$refs['table-list'].getCheckedRows();

                if (!this.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async (check) => {
                        console.log('deleteChecked. check :', check);
                        this.equipData = this.equipData.filter(item => {
                            return !checked.find(checkedProp => checkedProp.sysNodeName === item.sysNodeName);
                        })
                    });
                } else {
                    this.alertNoti("삭제할 목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
    },
}

</script>