<template>
    <div>
        <b-modal centered ref="inheritPoint" size="xl" @hidden="hideInheritPointMadal">
            <template #modal-header>
                <span class="ctrl-modal-header">영향 인자 목록</span>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary ctrl-modal-btn"
                        @click="hideInheritPointMadal">닫기</button>
                </div>
            </template>
            <div class="detail-container mb-3">
                <div class="detail-content">
                    <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="pointDetail" 
                        :useFilter="false" :transCodes="transCodes" :keyField="'sysNodeName'" :mode="'view'"
                        :customMaxHeight="'65vh'" :customRowHeight="'55%'" />
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TableListComponent from '@src/views/component/v2.1/ListDetailView/TableList.vue';
// import moment from 'moment'
// import backEndApi from '@src/api/backEndApi';

export default {
    props: ["data"],
    components: {
        TableListComponent,
    },
    data() {
        return {
            columns: [
                {
                    label: this.$t("표시명"),
                    field: "dispText",
                    type: "text",
                },  
                {
                    label: this.$t("계통 노드"),
                    field: "rowTitle",
                    type: "text",
                    compositionFields: ['sysNodeName', 'sysNodeIdx'],
                },             
                {
                    label: this.$t("관계유형"),
                    field: "ptReltnType",
                    type: "text",
                },
                {
                    label: this.$t("영향범위"),
                    field: "affectRange",
                    type: "text",
                },
                {
                    label: this.$t("관제점 ID"),
                    field: "ptIdx",
                    type: "text",
                },                
                {
                    label: this.$t("관제점 주소"),
                    field: "ptAddr2",
                    type: "text",
                    compositionFields: ['ptAddr', 'ptName'],
                },
                {
                    label: this.$t("집계유형"),
                    field: "ptTypeCode",
                    type: "text",
                },                
                {
                    label: this.$t("단위"),
                    field: "unitSmallCode",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: 'ptReltnType',
                    codes: this.$store.state.commonCodes.ptReltnType,
                },
                {
                    field: 'ptTypeCode',
                    codes: this.$store.state.commonCodes.ptTypeCode,
                },
                {
                    field: 'affectRange',
                    codes: this.$store.state.commonCodes.affectRange,
                },
                {
                    field: 'unitSmallCode',
                    codes: this.$store.state.units,
                },
            ],
            pointDetail: null,
        }
    },
    watch: {},
    computed: {
    },
    created() {},
    mounted() {
        console.log("InheritPointMadal Mounted!!!");
    },
    destroyed() {
        console.log("InheritPointMadal Destroyed!!!")
    },
    methods: {
        setData(data) {
            if(data) {
                this.pointDetail = data;
            } else {
                this.pointDetail = [];
            }
        },
        showInheritPointMadal() {
            this.$refs.inheritPoint.show();
        },
        hideInheritPointMadal() {
            this.$refs.inheritPoint.hide();
            // this.$emit("clear-data");
        },
    },
}
</script>

<style scoped>
.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.ctrl-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}
</style>